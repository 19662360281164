import React, {useEffect} from 'react'
import User from "../state/user";
import {get} from "../common/API";

const Test = () => {
    const user = User()

    useEffect(() => {
        let t = get({route: "/online", token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0aW1lc3RhbXAiOjE2NTg0MTk1ODUuODAzNzUsImV4cCI6MTY1ODQyMzE4NSwidXNlcm5hbWUiOiJhZG1pbiJ9._4ISSNj9qYrZwKNuejAf3MORcxO5OEUyH-7NkCgTgVM"})
    }, [])
    return <></>
}

export default Test