import React, {useEffect, useState} from 'react'
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import API, {get} from "../common/API";
import User from "../state/user";

const StyledLink = styled(Link)<{active?: number}>`
  text-decoration: none;
  margin-right: 15px;
  color: ${props => (props.active == 1 ? '#212529' : '#005895')};

  :hover {
    color: #212529;
  }
`

interface Props {
    to: string,
    icon?: string
    text?: string,
    overlay?: boolean
}

const StatusIndicator = styled.span<{ online?: boolean }>`
  color: ${props => (props.online ? 'green' : 'red')};
`

const Status = ({online}: { online?: boolean }) => {
    return <StatusIndicator online={online}>{online ? 'online' : 'offline'}</StatusIndicator>
}

const NavLink = ({to, icon, text, overlay}: Props) => {
    const location = useLocation()
    return <>
        {overlay ?
            <OverlayTrigger
                key={"bottom"}
                placement={"bottom"}
                overlay={
                    <Tooltip id={`tooltip-bottom`}>
                        {text}
                    </Tooltip>
                }
            >
                <StyledLink active={location.pathname == to ? 1: 0} to={to}>{icon ? <i className={icon}/> : null}</StyledLink>
            </OverlayTrigger>
            :
            <StyledLink active={location.pathname == to ? 1 : 0} to={to}>{icon ? <i className={icon}/> : null} {text ? text : null}</StyledLink>
        }
    </>
}


const Navbar = () => {
    const [online, set_online] = useState(false)
    const navigate = useNavigate()

    const user = User()

    useEffect(() => {
        const loadStatus = async () => {
            let t = await get({route: "/online", token: user.getToken()})

            set_online(true)
        }

        loadStatus()

    }, [])

    return <>
        <Row className={"mb-3"}>
            <Col className={"col-10 text-start"}>
                <NavLink text={"Home"} to={"/home"}/>
                <NavLink text={"Tables"} to={"/tables"}/>
                <NavLink text={"Ports"} to={"/ports"}/>
                <NavLink text={"Logs"} to={"/logs"} />
                <NavLink overlay text={"Settings"} to={"/settings"} icon={"bi bi-gear-fill"}/>
                <NavLink overlay text={"Logout"} to={"/logout"} icon={"bi bi-box-arrow-right"}/>
            </Col>
            <Col className={"col-2 text-end"}>
                Controller: <Status online={online}/>
            </Col>
        </Row>
        <hr />
    </>
}

export default Navbar