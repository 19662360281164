import React, {useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import styled from "styled-components";

interface Props {
    children: JSX.Element
}

const InfoBox = ({children}: Props) => {
    const [show, set_show] = useState(false)
    return <>
        <Modal show={show} onHide={() => {
            set_show(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    set_show(false)
                }}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
        <i onClick={() => {set_show(true)}} role="button" className="bi bi-question-circle-fill" />
    </>
}

export default InfoBox