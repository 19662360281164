import React, {useEffect, useState} from 'react'
import {Button, Col, Row, Table} from "react-bootstrap";
import Mirrorhaul from "../components/Mirrorhaul";
import {del as del_req, get} from "../common/API";
import User from "../state/user";
import Loader from "../components/Loader";
import ForwardView from "../components/ForwardView";
import sort_json from "../common/sort_json";
import MirrorhaulView from "../components/MirrorhaulView";
import InfoBox from "../components/InfoBox";
import BundlingView from "../components/BundlingView";

const Home = () => {
    const [loaded, set_loaded] = useState(false)
    const [ports, set_ports] = useState<[]>([])
    const [forward, set_forward] = useState<{ [name: string]: [] }>({})
    const [bundling, set_bundling] = useState<{ [name: string]: [] }>({})
    const [mirrorhaul, set_mirrorhaul] = useState<{ [name: string]: [] }>({})

    const user = User()

    const removeBundling = async (id: number) => {
        let del = await del_req({
            route: "/bundling/" + id,
            token: user.getToken()
        })

        if (del != undefined && del.status === 200) {
            await refresh()
        }
    }

    const removeForward = async (id: number) => {
        let del = await del_req({
            route: "/forward/" + id,
            token: user.getToken()
        })

        if (del != undefined &&  del.status === 200) {
            await refresh()
        }
    }

    const removeMirrorhaul = async (id: number) => {
        let del = await del_req({
            route: "/mirrorhaul/" + id,
            token: user.getToken()
        })

        if (del != undefined && del.status === 200) {
            await refresh()
        }
    }

    const devPortToFrontPanelPort = (sw: string, dev: number) => {
        let port = "Unknown"

        if (sw in ports) {
            (ports as any)[sw].forEach((p: { pid: number, port: number, channel: number }) => {
                if (p.pid == dev) {
                    port = p.port + "/" + p.channel
                }
            })
        }

        return port
    }

    const portRate = (sw: string, type: number, pid: number) => {
        let ret = 0

        if (sw in ports) {
            (ports as any)[sw].forEach((p: { pid: number, tx_rate: number, rx_rate: number }) => {
                if (p.pid == pid) {
                    if (type == 0) {
                        ret = p.tx_rate
                    } else {
                        ret = p.rx_rate
                    }
                }
            })
        }

        return ret
    }

    const devPortStatus = (sw: string, dev: number) => {
        let status = false

        if (sw in ports) {
            (ports as any)[sw].forEach((p: { pid: number, port: number, channel: number, status: boolean }) => {
                if (p.pid == dev) {
                    status = p.status
                }
            })
        }

        return status

    }

    const refresh = async () => {
        set_loaded(false)
        await loadPorts()
        await loadForward()
        await loadMirrorhaul()
        await loadBundling()
        set_loaded(true)
    }

    const loadPorts = async () => {
        let stats = await get({route: "/ports", token: user.getToken()})

        if (stats != undefined && stats.status === 200) {
            set_ports(sort_json(stats.data))
        }
    }

    const loadBundling = async () => {
        let stats = await get({route: "/bundling", token: user.getToken()})

        if (stats != undefined && stats.status === 200) {
            set_bundling(sort_json(stats.data))
        }
    }

    const loadForward = async () => {
        let stats = await get({route: "/forward", token: user.getToken()})

        if (stats != undefined && stats.status === 200) {
            set_forward(sort_json(stats.data))
        }
    }

    const loadMirrorhaul = async () => {
        let stats = await get({route: "/mirrorhaul", token: user.getToken()})

        if (stats != undefined && stats.status === 200) {
            set_mirrorhaul(sort_json(stats.data))
        }
    }

    useEffect(() => {
        refresh()

    }, [])

    const [modal, set_modal] = useState(false)

    return <Loader loaded={loaded}>
        <Mirrorhaul refresh={refresh} ports={ports} show={modal} onHide={() => set_modal(false)}/>
        <h5>Mirrorhaul Configuration</h5>

        <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
            <thead>
            <tr>
                <th>Action</th>
                <th>Configuration</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(bundling).length > 0 ?
                <tr>
                    <td>Bundling {" "}
                        <InfoBox>
                            <>
                                <h5>Bundling</h5>
                                <p>Traffic arriving on an ingress port is equipped with an egress tag and forwarded through a egress port.</p>
                                <p>A switch that receives a packet with an egress tag, forward the packet through the egress port specified in the egress tag.</p>
                            </>
                        </InfoBox>
                    </td>
                    <td>
                        {Object.keys(bundling).map((v, i) => {
                            return <BundlingView key={i} sw={v} remove={removeBundling} port_status={devPortStatus}
                                                port_mapper={devPortToFrontPanelPort} data={bundling[v]}/>
                        })}

                    </td>
                </tr>
                :
                null
            }
            {Object.keys(forward).length > 0 ?
                <tr>
                    <td>Forward {" "}
                        <InfoBox>
                            <>
                                <h5>Forward</h5>
                                <p>Instead of applying a Mirrorhaul process, traffic arriving on an ingress port
                                    (Ingress) can also be forced to be forwarded through a specific egress port
                                    (Egress).</p>
                                <p>Forward rules have higher priority than Mirrorhaul processes, i.e., when a
                                    forward rule for an ingress port exists, duplication is not applied.</p>
                            </>
                        </InfoBox>
                    </td>
                    <td>
                        {
                            Object.keys(forward).map((v, i) => {
                                return <ForwardView key={i} sw={v} remove={removeForward} port_status={devPortStatus}
                                                    port_mapper={devPortToFrontPanelPort} data={forward[v]}/>
                            })
                        }
                    </td>
                </tr>

                : null
            }
            
            {
                (Object.keys(mirrorhaul).length > 0) ?
                    <tr>
                        <td>Mirrorhaul {" "}
                            <InfoBox>
                                <>
                                    <h5>Mirrorhaul process</h5>
                                    <p>A Mirrorhaul process consists of a dupliation switch (Duplication) and a
                                        deduplication switch (Deduplication).</p>

                                    <h6>Duplication switch</h6>
                                    <p>A duplication switch duplicates packets arriving on a specific ingress (Ingress)
                                        to multiple egress ports (Egress).</p>

                                    <h6>Deduplication switch</h6>
                                    <p>A deduplication switch deduplicates packets arriving on multiple ingress ports
                                        (Ingress) to a single egress port (Egress).</p>

                                    <p>Only entries where both switches are connected are shown.</p>

                                </>
                            </InfoBox>
                        </td>
                        <td>
                            {
                                Object.keys(mirrorhaul).map((v, i) => {
                                    return <MirrorhaulView key={i} remove={removeMirrorhaul} port_status={devPortStatus}
                                                           port_mapper={devPortToFrontPanelPort}
                                                           rate={portRate}
                                                           data={mirrorhaul[v]}/>
                                })
                            }
                        </td>
                    </tr>
                    :
                    null
            }

            </tbody>
        </Table>
        <Row>
            <Col>
                <Button onClick={() => set_modal(true)}><i className="bi bi-plus"/></Button>
                {' '}
                <Button onClick={refresh} className={"ml-3"}><i className="bi bi-arrow-clockwise"/> Refresh</Button>
            </Col>
        </Row>
    </Loader>;
}

export default Home