import {Form, Table} from "react-bootstrap";
import {PortStatus} from "../sites/Ports";
import ConfirmBox from "./ConfirmBox";
import React, {useState} from "react";

import {patch} from "../common/API"
import User from "../state/user";

const BundlingEntry = ({
                           port_status,
                           port_mapper,
                           sw,
                           data,
                           remove
                       }: { port_status: (sw: string, dev: number) => boolean, port_mapper: (sw: string, dev: number) => string, sw: string, data: { id: number, ingress: number, egress: number, egress_tag: number, active: boolean }, remove: (id: number) => void }) => {

    const [active, set_active] = useState(data.active)
    const [enabled, set_enabled] = useState(true)

    const user = User()

    let ingress_status = port_status(sw, data.ingress)
    let egress_status = port_status(sw, data.egress)

    const onChange = async (event: any) => {
        set_active(!active)
        set_enabled(false)

        let r = await patch({route: "/bundling/" + data.id, body: {active: !active}, token: user.getToken()})

        if (r.status !== 200) {
            set_active(!active)
        }

        set_enabled(true)
    }


    return <tr>
        <td>{sw}</td>
        <td>{data.ingress} ({port_mapper(sw, data.ingress)} <PortStatus
            active={ingress_status}/>)
        </td>
        <td>{data.egress} ({port_mapper(sw, data.egress)} <PortStatus
            active={egress_status}/>)
        </td>
        <td>{data.egress_tag}
        </td>
        <td>
            <Form.Check
                inline
                checked={active}
                disabled={!enabled}
                onChange={onChange}
                type="switch"
                role="button"
            />
            <ConfirmBox onConfirm={() => remove(data["id"])}
                        text={"Do you want to delete bundling rule " + port_mapper(sw, data.ingress) + "->" + port_mapper(sw, data.egress) + " on switch " + sw + "?"}>
                <i className={"bi bi-trash-fill"}/>
            </ConfirmBox>
        </td>
    </tr>


}
const BundlingView = ({
                          port_status,
                          port_mapper,
                          sw,
                          data,
                          remove
                      }: { port_status: (sw: string, dev: number) => boolean, port_mapper: (sw: string, dev: number) => string, sw: string, data: { ingress: number, egress: number }[], remove: (id: number) => void }) => {

    return <Table bordered hover size={"sm"} responsive={true}>
        <thead className={"table-dark"}>
        <tr>
            <th>Switch</th>
            <th>Ingress</th>
            <th>Egress</th>
            <th>Egress Tag</th>
            <th>Options</th>
        </tr>
        </thead>
        <tbody>

        {data.map((v: any, i: number) => {
            return <BundlingEntry key={i} port_status={port_status} port_mapper={port_mapper} sw={sw} data={v}
                                  remove={remove}/>
        })
        }


        </tbody>
    </Table>
}

export default BundlingView