import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {PortInterface} from "../common/Interfaces";
import {post} from "../common/API";
import User from "../state/user";

interface Props {
    show: boolean,
    onHide: () => void,
    refresh: () => void,
    ports: []
}

// return select list with ports of a switch
const SwitchList = ({
                        ports,
                        onChange
                    }: { ports: Props["ports"], onChange: (event: ChangeEvent<HTMLSelectElement>) => void }) => {
    return <Form.Select required onChange={onChange}>
        <option value="">Select switch</option>
        {
            Object.keys(ports).map((v, i) => {
                return <option key={i} value={v}>{v}</option>
            })
        }
    </Form.Select>
}

// return select list with ports of a switch
const PortsOfSwitch = ({
                           ports,
                           sw,
                           onChange
                       }: { ports: Object, sw: string, onChange: (event: ChangeEvent<HTMLSelectElement>) => void }) => {
    const [p, set_p] = useState<PortInterface[]>([])

    useEffect(() => {
        if (sw in ports) {
            set_p((ports as any)[sw])
        } else {
            set_p([])
        }
    }, [sw])

    return <Form.Select required onChange={onChange}>
        <option value="">Select port</option>
        {
            p.map((v: PortInterface, i: number) => {
                if (v.loopback == "BF_LPBK_NONE") { // do not show loopback ports
                    return <option key={i} value={v.pid}>{v.port}/{v.channel}</option>
                }
            })
        }
    </Form.Select>
}

const Mirrorhaul = ({ports, show, onHide, refresh}: Props) => {
    const [data, set_data] = useState<{
        sw1: string, sw2: string, action: string, forward_ingress: number,
        forward_egress: number, bundling_ingress: number, bundling_egress: number, bundling_egress_tag: number, duplicate_ingress: number, duplicate_egress_1: number, duplicate_egress_2: number,
        deduplicate_ingress_1: number, deduplicate_ingress_2: number,
        deduplicate_egress: number
    }>({
        sw1: "",
        sw2: "",
        action: "none",
        forward_ingress: 0,
        forward_egress: 0,
        bundling_ingress: 0,
        bundling_egress: 0,
        bundling_egress_tag: 0,
        duplicate_ingress: 0,
        duplicate_egress_1: 0,
        duplicate_egress_2: 0,
        deduplicate_ingress_1: 0,
        deduplicate_ingress_2: 0,
        deduplicate_egress: 0
    })

    const user = User()

    const hide = () => {
        set_data({...data, action: "none"})
        onHide()
    }

    const action_change = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, action: event.target.value})
    }

    const onChangeSw1 = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, sw1: event.target.value})
    }

    const onChangeSw2 = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, sw2: event.target.value})
    }

    const forward_ingress = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, forward_ingress: parseInt(event.target.value)})
    }

    const forward_egress = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, forward_egress: parseInt(event.target.value)})
    }

    const bundling_ingress = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, bundling_ingress: parseInt(event.target.value)})
    }

    const bundling_egress = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, bundling_egress: parseInt(event.target.value)})
    }

    const bundling_egress_tag = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, bundling_egress_tag: parseInt(event.target.value)})
    }

    const duplicate_ingress = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, duplicate_ingress: parseInt(event.target.value)})
    }

    const duplicate_egress_1 = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, duplicate_egress_1: parseInt(event.target.value)})
    }

    const duplicate_egress_2 = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, duplicate_egress_2: parseInt(event.target.value)})
    }

    const deduplicate_ingress_1 = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, deduplicate_ingress_1: parseInt(event.target.value)})
    }

    const deduplicate_ingress_2 = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, deduplicate_ingress_2: parseInt(event.target.value)})
    }

    const deduplicate_egress = (event: ChangeEvent<HTMLSelectElement>) => {
        set_data({...data, deduplicate_egress: parseInt(event.target.value)})
    }

    const onSubmit = async (event: any) => {
        event.preventDefault()

        switch (data.action) {
            case "forward":
                const r = await post({
                    route: "/forward", body: {
                        "switch": data.sw1,
                        "ingress": data.forward_ingress,
                        "egress": data.forward_egress
                    },
                    token: user.getToken()
                })

                if (r.status === 201) {
                    onHide()
                    refresh()
                }
                break
            case "bundling":
                const b = await post({
                    route: "/bundling", body: {
                        "switch": data.sw1,
                        "ingress": data.bundling_ingress,
                        "egress": data.bundling_egress,
                        "egress_tag": data.bundling_egress_tag
                    },
                    token: user.getToken()
                })

                if (b.status === 201) {
                    onHide()
                    refresh()
                }
                break
            case "mirrorhaul":
                const m = await post({
                    route: "/mirrorhaul", body: {
                        "switch1": data.sw1,
                        "switch2": data.sw2,
                        "duplication_ingress": data.duplicate_ingress,
                        "duplication_egress_1": data.duplicate_egress_1,
                        "duplication_egress_2": data.duplicate_egress_2,
                        "deduplication_ingress_1": data.deduplicate_ingress_1,
                        "deduplication_ingress_2": data.deduplicate_ingress_2,
                        "deduplication_egress": data.deduplicate_egress
                    },
                    token: user.getToken()
                })

                if (m.status === 201) {
                    hide()
                    refresh()
                }
                break

        }
    }

    return <Modal show={show} onHide={hide} size={"lg"}>
        <Modal.Header closeButton>
            <Modal.Title>Add action</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
            <Modal.Body>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Action
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={action_change}>
                            <option value="">Select action</option>
                            <option value="forward">Forward</option>
                            <option value="bundling">Bundling</option>
                            <option value="mirrorhaul">Mirrorhaul</option>
                        </Form.Select>
                    </Col>
                </Form.Group>

                {data.action == "forward" ?
                    <>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Switch
                            </Form.Label>
                            <Col sm={10}>
                                <SwitchList ports={ports} onChange={onChangeSw1}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Ingress
                            </Form.Label>
                            <Col sm={10}>
                                <PortsOfSwitch ports={ports} sw={data.sw1} onChange={forward_ingress}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Egress
                            </Form.Label>
                            <Col sm={10}>
                                <PortsOfSwitch ports={ports} onChange={forward_egress} sw={data.sw1}/>
                            </Col>
                        </Form.Group>
                    </>
                    :
                    null
                }

                {data.action == "bundling" ?
                    <>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Switch
                            </Form.Label>
                            <Col sm={10}>
                                <SwitchList ports={ports} onChange={onChangeSw1}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Ingress
                            </Form.Label>
                            <Col sm={10}>
                                <PortsOfSwitch ports={ports} sw={data.sw1} onChange={bundling_ingress}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Egress
                            </Form.Label>
                            <Col sm={10}>
                                <PortsOfSwitch ports={ports} onChange={bundling_egress} sw={data.sw1}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Receiving Switch
                            </Form.Label>
                            <Col sm={10}>
                                <SwitchList ports={ports} onChange={onChangeSw2}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Receiving Switch Egress (Egress Tag)
                            </Form.Label>
                            <Col sm={10}>
                                <PortsOfSwitch ports={ports} sw={data.sw2} onChange={bundling_egress_tag}/>
                            </Col>
                        </Form.Group>
                    </>
                    :
                    null
                }

                {data.action == "mirrorhaul" ?
                    <>
                        <h5>Duplication</h5>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Switch
                            </Form.Label>
                            <Col sm={10}>
                                <SwitchList ports={ports} onChange={onChangeSw1}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Ingress
                            </Form.Label>
                            <Col sm={10}>
                                <PortsOfSwitch onChange={duplicate_ingress} ports={ports} sw={data.sw1}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Egress
                            </Form.Label>
                            <Col sm={10}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <PortsOfSwitch onChange={duplicate_egress_1} ports={ports} sw={data.sw1}/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <PortsOfSwitch onChange={duplicate_egress_2} ports={ports} sw={data.sw1}/>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Form.Group>

                        {data.sw1 != "" ?
                            <>
                                <h5>Deduplication</h5>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Switch
                                    </Form.Label>
                                    <Col sm={10}>
                                        <SwitchList ports={ports} onChange={onChangeSw2}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Ingress
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                                <PortsOfSwitch onChange={deduplicate_ingress_1} ports={ports}
                                                               sw={data.sw2}/>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <PortsOfSwitch onChange={deduplicate_ingress_2} ports={ports}
                                                               sw={data.sw2}/>
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Egress
                                    </Form.Label>
                                    <Col sm={10}>
                                        <PortsOfSwitch onChange={deduplicate_egress} ports={ports} sw={data.sw2}/>
                                    </Col>
                                </Form.Group>
                            </>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type={"submit"}>Add rule</Button>
                <Button variant="secondary" onClick={hide}>
                    Close
                </Button>
            </Modal.Footer>
        </form>
    </Modal>
}

export default Mirrorhaul