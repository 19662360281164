import React, {ChangeEvent, useState} from 'react'

import {Button, Container, Form} from 'react-bootstrap'

import styled from 'styled-components'

import User from "../state/user"
import {useNavigate} from "react-router-dom";
import {post} from "../common/API"
import Loader from "../components/Loader";
import Logo from "../assets/logo.png"

const StyledContainer = styled(Container)`
  background: #FFF;
  max-width: 400px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid #e8e8e8;
`

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledLogo = styled.img`
    width: 100%;
`

const Login = () => {
    const [username, set_username] = useState("")
    const [password, set_password] = useState("")
    const [loaded, set_loaded] = useState(true)

    const user = User()

    const navigate = useNavigate()

    const update_username = (event: ChangeEvent<HTMLInputElement>) => {
        set_username(event.target.value)
    }

    const update_password = (event: ChangeEvent<HTMLInputElement>) => {
        set_password(event.target.value)
    }

    const login = async (username: string, password: string) => {
        set_loaded(false)
        const r = await post({route: "/login", body: {username: username, password: password}})
        set_loaded(true)

        if (r.status === 200) {
            user.login(r.data.username, r.data.token)
            navigate("/home")
        }
    }

    return <Wrapper>
        <Loader loaded={loaded}>
            <StyledContainer>
                <StyledLogo src={Logo} />
                <Form onSubmit={(event) => {
                    event.preventDefault();
                    login(username, password)
                }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter username" onChange={update_username}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={update_password}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Login
                    </Button>
                </Form>
            </StyledContainer>
        </Loader>
    </Wrapper>
}

export default Login