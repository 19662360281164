import React, {useEffect} from "react"

import User from "../state/user"
import {useNavigate} from "react-router-dom";

export default () => {
    const user = User()
    const navigate = useNavigate()

    useEffect(() => {
        user.logout()
        navigate("/home")
    }, [])

    return <></>
}