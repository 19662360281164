import React, {useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import styled from "styled-components";

interface Props {
    onConfirm: () => void,
    text: string
    children: JSX.Element
}

const Wrapper = styled.div`
    display: inline-block;
`

const ConfirmBox = ({onConfirm, text, children}: Props) => {
    const [show, set_show] = useState(false)
    return  <><Modal show={show} onHide={() => {set_show(false)}}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => {set_show(false)}}>
                Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
        <Wrapper role="button" onClick={() => set_show(true)}>
            {children}
        </Wrapper>
    </>
}

export default ConfirmBox