import axios from "axios"
import {AxiosResponse} from "axios"
import Config from "../config";
import { ReactNode, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import User from "../state/user";

const instance = axios.create({
    baseURL: Config.API_URL
})

interface Request {
    route: string,
    body?: any,
    token?: string
}

const getHeader = (token?: string) => {
    const headers: {} = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'headers': {
            'Authorization': token
        },
        timeout: 0
    }

    return headers
}


const AxiosInterceptor = ({ onError, children } : {onError: (message: string) => void, children: JSX.Element}) => {
    const navigate = useNavigate()
    const user = User()

    useEffect(() => {

        const resInterceptor = (response: AxiosResponse) => {
            return response;
        };

        const errInterceptor = (error: any) => {
            console.log(error)
            if(error.response.status === 400) {
                onError(error.response.data.message)
            }
            else if (error.response.status === 401) {
                console.log("Logout should happen")
                user.logout()
                onError(error.response.data.message)
                navigate("/logout")
            }
            else if(error.response.status === 500) {
                if("data" in error.response) {
                    if("message" in error.response.data) {
                        onError(error.response.data.message)
                    }
                }
                else {
                    onError("Internal Server Error.")
                }
            }
            else if(error.response.status === 404) {
                console.log("404")
                onError("Request endpoint not found.")
            }
            else if(error.response.status === 0) {
                //onError("Undefined response status.")
                console.log("Undefined response status.")
            }
            else {
                console.log(error)
            }

            return Promise.resolve()
        };

        const interceptor = instance.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );

        return () => instance.interceptors.response.eject(interceptor);
    }, []);

    return children;
};

const get = async (request: Request) => {
    try {
        return await instance.get(request.route, getHeader(request.token))
    }
    catch (e) {
        return await instance.get(request.route, getHeader(request.token))
    }

}

const post = async (request: Request) => {
    try {
        return await instance.post(request.route, request.body, getHeader(request.token))
    }
    catch(e) {
        return await instance.delete(request.route, getHeader(request.token))
    }
}

const del = async (request: Request) => {
    try {
        return await instance.delete(request.route, getHeader(request.token))
    }
    catch(e) {
        return await instance.delete(request.route, getHeader(request.token))
    }
}

const put = async (request: Request) => {
    try {
        return await instance.put(request.route, request.body, getHeader(request.token))
    }
    catch (e) {
        return await instance.delete(request.route, getHeader(request.token))
    }
}

const patch = async (request: Request) => {
    try {
        return await instance.patch(request.route, request.body, getHeader(request.token))
    }
    catch(e) {
        return await instance.delete(request.route, getHeader(request.token))
    }
}


export default instance
export { AxiosInterceptor, get, post, del, put, patch}