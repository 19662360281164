import React, {useEffect, useState} from 'react'
import Loader from "../components/Loader";
import {del, get} from '../common/API'
import User from "../state/user";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import Pagination from 'react-bootstrap-pagination-logic'
import ConfirmBox from "../components/ConfirmBox";


const Logs = () => {
    const [loaded, set_loaded] = useState(false)
    const [logs, set_logs] = useState<{ keys: string[], switches: string[], total: number, logs: { key: string, tstamp: string, msg: string, switch: string }[] }>({
        switches: [],
        keys: [],
        total: 0,
        logs: []
    })
    const [topic, set_topic] = useState("All")
    const [sw, set_sw] = useState("All")
    const [active_site, set_active_site] = useState(1)
    const [offset, set_offset] = useState(0)

    const user = User()
    const limit = 15

    const loadLogs = async () => {
        let stats = await get({
            route: "/logs?key=" + topic + "&limit=" + limit + "&offset=" + offset + "&switch=" + sw,
            token: user.getToken()
        })

        if (stats.status === 200) {
            set_logs(stats.data)
            set_loaded(true)
        }

    }

    const refresh = () => {
        set_loaded(false)
        loadLogs()
    }

    const deleteLogs = async () => {
        await del({
            route: "/logs",
            token: user.getToken()
        })
    }


    useEffect(() => {
        refresh()
    }, [topic, offset, sw])


    return <Loader loaded={loaded}>
        <Row className={"mb-3"}>
            <Col className={"col-3"}>
                <Form.Select defaultValue={sw} onChange={(event: any) => {
                    set_offset(0);
                    set_active_site(1);
                    set_sw(event.target.value)
                }}>
                    <option value="All">All</option>
                    {logs.switches.sort().map((v, i) => {
                        return <option key={i} value={v}>{v}</option>
                    })
                    }
                </Form.Select>
            </Col>
            <Col className={"col-3"}>
                <Form.Select defaultValue={topic} onChange={(event: any) => {
                    set_offset(0);
                    set_active_site(1);
                    set_topic(event.target.value)
                }}>
                    <option value="All">All</option>
                    {logs.keys.sort().map((v, i) => {
                        return <option key={i} value={v}>{v}</option>
                    })
                    }
                </Form.Select>
            </Col>
            <Col className={"text-end"}>
                <ConfirmBox onConfirm={async () => {
                    await deleteLogs();
                    refresh()
                }} text={"Do you want to delete all logs?"}>
                    <Button>Clear Logs</Button>
                </ConfirmBox>
            </Col>
        </Row>
        <Row>
            <Col>
                <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
                    <thead className={"table-dark"}>
                    <tr>
                        <th className={"col-1"}>Switch</th>
                        <th className={"col-2"}>Key</th>
                        <th className={"col-2"}>Timestamp</th>
                        <th className={"col-7"}>Message</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Array.from(logs.logs).map((v, i) => {
                        if (v.key == topic || topic == "All") {
                            return <tr>
                                <td>{v.switch}</td>
                                <td>{v.key}</td>
                                <td>{v.tstamp}</td>
                                <td>{v.msg}</td>
                            </tr>
                        }
                    })
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>
        {Math.ceil(logs.total / limit) > 1 ?
            <Pagination
                current_page={active_site}
                last_page={Math.ceil(logs.total / limit)}
                position="left"
                handlePageChange={(page: number) => {
                    console.log(page)
                    set_offset(limit * (page - 1))
                    set_active_site(page)
                }}
                    />
            :
            null
        }

        <Button onClick={refresh} className={"ml-3"}><i className="bi bi-arrow-clockwise"/> Refresh</Button>

    </Loader>
}

export default Logs