import createPersistedState from 'use-persisted-state';
import axios from "axios";
import API from "../common/API";

const useState = createPersistedState('user');


const defaultUser = {
    token: "",
    username: "",
    loggedIn: false
}

export interface UserProps {
    token: string,
    username: string,
    loggedIn: boolean
}


const User = () => {

    const [user, setUser] = useState(defaultUser);

    return {
        user,
        setToken: (accessToken: string) => setUser({...user as UserProps, token: accessToken}),
        getToken: () => (user as UserProps).token,
        login: (name: string, accessToken: string) => {
            console.log("login " + accessToken);
            setUser({...user as UserProps, loggedIn: true, username: name, token: accessToken})
        },
        logout: () => {
            console.log("logout");
            setUser({...user as UserProps, loggedIn: false, token: "-1"})
        }
    };
};

export default User;