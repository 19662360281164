import React, {useEffect, useState} from 'react'
import {Button, Col, Row, Table} from "react-bootstrap";
import ConfirmBox from "../components/ConfirmBox";
import {get} from "../common/API";
import sort_json from "../common/sort_json";
import User from "../state/user";
import Loader from "../components/Loader";
import styled from "styled-components";

const handlestatus = (status: number) => {
    switch (status) {
        case 0:
            return "red"
        case 1:
            return "orange"
            break
        case 2:
            return "green"
            break
        default:
            return "red"
    }
}
const StyledStatus = styled.span<{ status: number }>`
  color: ${props => handlestatus(props.status)}
}
`
const Status = ({status}: { status: number }) => {
    return <StyledStatus status={status}>
        {(() => {
            switch (status) {
                case 0:
                    return "Not running"
                    break
                case 1:
                    return "Starting"
                    break
                case 2:
                    return "Running"
                    break
                default:
                    return "Offline"
                    break
            }
        })()
        }
    </StyledStatus>
}

const StyledConnection = styled.span<{ connected: boolean }>`
  color: ${props => props.connected ? "green" : "red"}
}
`
const Connection = ({connected}: { connected: boolean }) => {
    return <StyledConnection connected={connected}>{connected ? "Connected" : "Not connected"}</StyledConnection>
}

const Settings = () => {
    const [loaded, set_loaded] = useState(false)
    const [data, set_data] = useState<{ [name: string]: { ip: string, grpc_bf: number, status: number, connected: boolean } }>({})
    const user = User()

    const loadData = async () => {
        set_loaded(false)

        let stats = await get({route: "/system", token: user.getToken()})

        if (stats.status === 200) {
            set_data(sort_json(stats.data))
            set_loaded(true)
        }

    }

    const refresh = () => {
        loadData()
    }
    useEffect(() => {
        loadData()

    }, [])

    const reload = async () => {
        set_loaded(false)
        await get({route: "/reload", token: user.getToken()})
        set_loaded(true)
    }

    const reset = async () => {
        set_loaded(false)
        await get({route: "/reset", token: user.getToken()})
        set_loaded(true)
    }

    return <Loader loaded={loaded}>
        <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
            <thead className={"table-dark"}>
            <tr>
                <th>Name</th>
                <th>IP</th>
                <th>GRPC Port</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(data).map((v, i) => {
                return <tr key={i}>
                    <td>{v}</td>
                    <td>{data[v].ip}</td>
                    <td>{data[v].grpc_bf}</td>
                    <td><Status status={data[v].status}/> | <Connection connected={data[v].connected}/></td>

                </tr>
            })}
            </tbody>
        </Table>

        <Button onClick={refresh} className={"ml-3"}><i className="bi bi-arrow-clockwise"/> Refresh</Button>
        {" "}

        <ConfirmBox onConfirm={reload}
                    text={"Reload entries will disconnect the switches. Thereby, connection will be re-initiated by the switches and all entries will be re-written. "}>
            <Button variant="danger"><i className="bi bi-arrow-clockwise"/> Reload entries</Button>
        </ConfirmBox>
        {" "}
        {/*<ConfirmBox onConfirm={reset} text={"Reset will delete all ports / table entries / configurations and clears the database. This may take a few seconds."}>*/}
        {/*    <Button variant="danger"><i className="bi bi-x-octagon-fill"/> Reset</Button>*/}
        {/*</ConfirmBox>*/}
    </Loader>
}

export default Settings