const sort_json = (json: any) => {
    return Object.keys(json).sort().reduce(
        (obj: any, key) => {
            obj[key] = json[key];
            return obj;
        },
        {}
    );
}

export default sort_json