import React, {ChangeEvent, useEffect, useState} from 'react'
import Loader from "../components/Loader";
import ConfirmBox from "../components/ConfirmBox";
import {del as del_req, get, post} from '../common/API'
import {Button, Col, Form, Modal, Row, Tab, Table, Tabs} from "react-bootstrap";
import User from "../state/user";
import styled from "styled-components";

import sort_json from "../common/sort_json";
import InfoBox from "../components/InfoBox";

interface Props {
    onError: (message: string) => void
}

interface AddPortProps {
    show: boolean,
    onHide: () => void,
    refresh: () => void,
    switches: string[]
}

export const PortStat = styled.span<{ active: boolean }>`
  color: ${props => (props.active ? 'green' : 'red')};
`

export const PortStatus = ({active}: { active: boolean }) => {
    return <PortStat active={active}>
        {active ?
            <i className="bi bi-arrow-up-circle-fill"/>
            :
            <i className="bi bi-arrow-down-circle-fill"/>
        }
    </PortStat>
}

const PortStatistics = ({
                            pid,
                            stats,
                            power
                        }: { pid: number, stats: { [name: string]: number }, power: { [name: string]: { tx_pwr: string, rx_pwr: string, tx_bias: string } } }) => {
    const [show, set_show] = useState(false)
    const [loaded, set_loaded] = useState(true)

    return <>
        <Modal size="xl" show={show} onHide={() => set_show(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Port Statistics for Port {pid}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loader loaded={loaded}>
                    {Object.keys(power).length > 0 ?
                        <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
                            <thead className={"table-dark"}>
                            <tr>
                                <th>Channel</th>
                                <th>TX Power</th>
                                <th>RX Power</th>
                                <th>TX Bias</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(power).map((v, i) => {
                                return <tr>
                                    <td>{v}</td>
                                    <td>{power[v].tx_pwr}</td>
                                    <td>{power[v].rx_pwr}</td>
                                    <td>{power[v].tx_bias}</td>
                                </tr>
                            })}


                            </tbody>
                        </Table>
                        :
                        null
                    }
                    <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
                        <tbody>
                        {Object.keys(stats).map((v, i) => {
                            return <tr>
                                <th className={"col-6 table-dark"}>{v.replace("$", "")}</th>
                                <td>{stats[v]}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Loader>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => set_show(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <i role="button" onClick={() => set_show(true)} className="bi bi-clipboard-data-fill"/>
    </>
}

const AddPort = ({show, onHide, refresh, switches}: AddPortProps) => {
    const auto_neg_name_mapping = ["Auto", "On", "Off"]
    const [sw, set_sw] = useState("")
    const [port, set_port] = useState(0)
    const [channel, set_channel] = useState(0)
    const [speed, set_speed] = useState(0)
    const [auto_neg, set_auto_neg] = useState(0)
    const [fec, set_fec] = useState(0)

    const fec_mapping = ["NONE", "FIRECODE", "REED SOLOMON"]

    const user = User()

    const onChangeSwitch = (event: ChangeEvent<HTMLSelectElement>) => {
        set_sw(event.target.value)
    }

    const onChangePort = (event: ChangeEvent<HTMLSelectElement>) => {
        set_port(parseInt(event.target.value))
    }

    const onChangeChannel = (event: ChangeEvent<HTMLSelectElement>) => {
        set_channel(parseInt(event.target.value))
    }

    const onChangeSpeed = (event: ChangeEvent<HTMLSelectElement>) => {
        set_speed(parseInt(event.target.value))
    }

    const onChangeAutoNeg = (event: ChangeEvent<HTMLSelectElement>) => {
        set_auto_neg(parseInt(event.target.value))
    }

    const onChangeFEC = (event: ChangeEvent<HTMLSelectElement>) => {
        set_fec(parseInt(event.target.value))
    }

    const onSubmit = async (event: any) => {
        event.preventDefault()

        const r = await post({
            route: "/ports", body: {
                switch: sw, port: port, channel: channel, speed: speed,
                auto_neg: auto_neg, fec: fec
            },
            token: user.getToken()
        })

        if (r.status === 201) {
            onHide()
            refresh()
        }
    }

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Add port</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
            <Modal.Body>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Switch
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={onChangeSwitch}>
                            <option value="">Select switch</option>
                            {
                                switches.map((v, i) => {
                                    return <option key={i} value={v}>{v}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Port
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={onChangePort}>
                            <option value="">Select port</option>
                            {
                                Array.from(Array(32).keys()).map((v, i) => {
                                    return <option key={i} value={v + 1}>{v + 1}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Channel
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={onChangeChannel}>
                            <option value="">Select channel</option>
                            {
                                Array.from(Array(4).keys()).map((v, i) => {
                                    return <option key={i} value={v}>{v}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Speed
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={onChangeSpeed}>
                            <option value="">Select speed</option>
                            {
                                [[1, "1G"], [2, "10G"], [3, "25G"], [4, "40G"], [6, "50G"], [7, "100G"]].map((v, i) => {
                                    return <option key={i} value={v[0]}>{v[1]}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Auto Neg
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={onChangeAutoNeg}>
                            <option value="">Select auto neg</option>
                            {
                                [0, 1, 2].map((v, i) => {
                                    return <option key={i} value={v}>{auto_neg_name_mapping[v]}</option>
                                })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        FEC {" "}
                        <InfoBox>
                            <p>For Tofino-Tofino connections, FEC should be set to REED SOLOMON.</p>
                        </InfoBox>
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select required onChange={onChangeFEC}>
                            <option value="">Select FEC</option>
                            {
                                [0, 1, 2].map((v, i) => {
                                    if (i != 1 || speed != 7) {
                                        return <option key={i} value={v}>{fec_mapping[v]}</option>
                                    }
                                })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type={"submit"}>
                    Add port
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </form>
    </Modal>

}

const Ports = () => {
    const [loaded, set_loaded] = useState(false)
    const [ports, set_ports] = useState({})
    const [modal, set_modal] = useState(false)

    const fec_mapping: { [name: string]: string } = {
        "BF_FEC_TYP_NONE": "None",
        "BF_FEC_TYP_FC": "Firecode",
        "BF_FEC_TYP_REED_SOLOMON": "Reed Solomon"
    }
    const auto_neg_mapping: { [name: string]: string } = {
        "PM_AN_DEFAULT": "Auto",
        "PM_AN_FORCE_DISABLE": "Off",
        "PM_AN_FORCE_ENABLE": "On"
    }

    const speed_mapping: { [name: string]: string } = {
        "BF_SPEED_1G": "1G",
        "BF_SPEED_10G": "10G",
        "BF_SPEED_25G": "25G",
        "BF_SPEED_40G": "40G",
        "BF_SPEED_50G": "50G",
        "BF_SPEED_100G": "100G"
    }

    const loopback_mapping: { [name: string]: string } = {
        "BF_LPBK_NONE": "Off",
        "BF_LPBK_MAC_NEAR": "On"
    }

    const user = User()

    const loadPorts = async () => {
        let stats = await get({route: "/ports", token: user.getToken()})

        if (stats.status === 200) {
            set_ports(sort_json(stats.data))
            set_loaded(true)
        }

    }

    const refresh = () => {
        set_loaded(false)
        loadPorts()
    }

    const remove = async (sw: string, port: number, channel: number) => {
        let del = await del_req({
            route: "/ports/" + sw + "/" + port + "/" + channel,
            token: user.getToken()
        })

        if (del.status === 200) {
            refresh()
        }
    }

    useEffect(() => {
        loadPorts()

    }, [])


    return <Loader loaded={loaded}>
        <AddPort show={modal} refresh={refresh} onHide={() => set_modal(false)}
                 switches={Object.keys(ports).map((v, i) => {
                     return v
                 })}/>
        <Tabs
            defaultActiveKey={Object.keys(ports)[0] ?? ""}
            className="mb-3 mt-3"
        >
            {Object.keys(ports).map((v, i) => {
                let sw = v;
                return <Tab key={i} eventKey={v} title={v}>
                    <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
                        <thead className={"table-dark"}>
                        <tr>
                            <th>PID</th>
                            <th>Port</th>
                            <th>Pipe</th>
                            <th>Speed</th>
                            <th>Auto Negotiation</th>
                            <th>FEC</th>
                            <th>Loopback</th>
                            <th>TX Rate (L2)</th>
                            <th>RX Rate (L2)</th>
                            <th>Status</th>
                            <th>Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(ports as any)[v].map((v: any, i: number) => {
                            return <tr key={i}>
                                <td>{v["pid"]}</td>
                                <td>{v['port']}/{v["channel"]}</td>
                                <td>{v["pipe"]}</td>
                                <td>{speed_mapping[(v['speed']) as string]}</td>
                                <td>{auto_neg_mapping[v['auto_neg']]}</td>
                                <td>{fec_mapping[v['fec']]}</td>
                                <td>{loopback_mapping[v["loopback"]]}</td>
                                <td>{(v["tx_rate"] * 8 / 10 ** 9).toFixed(2)} Gbps</td>
                                <td>{(v["rx_rate"] * 8 / 10 ** 9).toFixed(2)} Gbps</td>
                                <td><PortStatus active={v['status']}/></td>
                                <td>{loopback_mapping[v["loopback"]] == "Off" ?
                                    <ConfirmBox onConfirm={() => remove(sw, v["port"], v["channel"])}
                                                text={"Do you want to delete port " + v['port'] + "/" + v["channel"] + "?"}>
                                        <i className={"bi bi-trash-fill"}/>
                                    </ConfirmBox>
                                    : null}
                                    {" "}
                                    <PortStatistics pid={v["pid"]} stats={v["stats"]} power={v["power"]}/>
                                </td>
                            </tr>
                        })
                        }
                        </tbody>
                    </Table>
                </Tab>
            })
            }
        </Tabs>
        <Row>
            <Col>
                <Button onClick={() => set_modal(true)}><i className="bi bi-plus"/></Button>
                {' '}
                <Button onClick={refresh} className={"ml-3"}><i className="bi bi-arrow-clockwise"/> Refresh</Button>
            </Col>
        </Row>
    </Loader>
}

export default Ports