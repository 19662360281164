import React, {useState} from 'react';

import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom"

import Login from "./sites/Login"

import Home from "./sites/Home"
import ErrorView from "./components/ErrorView";

import User, {UserProps} from "./state/user"
import Config from "./config";

import {Container} from "react-bootstrap";
import styled from "styled-components";
import Logout from "./sites/Logout";
import Ports from "./sites/Ports";
import Navbar from "./components/Navbar";
import Tables from "./sites/Tables";
import {AxiosInterceptor} from "./common/API";
import Settings from "./sites/Settings";
import Logo from "./assets/logo.png";
import Logs from "./sites/Logs";
import Test from "./sites/Teste";

function App() {
  const [error, set_error] = useState(false)
  const [message, set_message] = useState("")
  const [time, set_time] = useState("00:00")

  const user = User().user as UserProps

  const setError = (msg: string) => {
    set_error(true)
    set_message(msg)

    let now = new Date()

    set_time(now.getHours() + ":" + now.getMinutes())
  }

  const Wrapper = styled.div`
    margin-top: 5%;
  `

  const StyledImg = styled.img`
    width: 30%;
  `


  return <>
    <ErrorView error={error} message={message} time={time} close={() => set_error(false)}/>
    <Router basename={Config.BASE_PATH}>
      <AxiosInterceptor onError={setError}>
        {user.loggedIn ?
            <Container fluid={"lg"} className={"pb-2"}>
              <Wrapper>
                <StyledImg src={Logo} />
                <Navbar />
                <Routes>
                  <Route path={""} element={<Home />}/>
                  <Route path={"/home"} element={<Home />}/>
                  <Route path={"/settings"} element={<Settings />}/>
                  <Route path={"/tables"} element={<Tables />}/>
                  <Route path={"/ports"} element={<Ports />}/>
                  <Route path={"/logs"} element={<Logs />}/>
                  <Route path={"/test"} element={<Test />}/>
                  <Route path={"/logout"} element={<Logout/>}/>
                </Routes>
              </Wrapper>
            </Container>
            :
            <Login />
        }
      </AxiosInterceptor>
    </Router>
  </>
}

export default App;