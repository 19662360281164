import React, {useEffect, useState} from 'react'
import {get} from "../common/API";
import {useNavigate} from "react-router-dom";
import User from "../state/user";
import Loader from "../components/Loader";
import {Button, Tab, Table, Tabs} from "react-bootstrap";
import sort_json from "../common/sort_json";

interface Props {
    onError: (message: string) => void
}

interface TableViewProps {
    name: string
    data: { data: Object, key: Object }[]
}

const TableView = ({name, data}: TableViewProps) => {
    return <>
        <h5>{name}</h5>
        <Table striped bordered hover size="sm" className={"mt-3 mb-3"}>
            <thead className={"table-dark"}>
            <tr>
                <th>#</th>
                <th>Key</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {data.map((v: { key: any, data: any }, i) => {
                return <tr key={i}>
                    <td>{i+1}</td>
                    <td>
                        <Table bordered hover size={"sm"} responsive={true}>
                            <thead>
                            <tr>
                                {Object.keys(v.key).map((k, i) => {
                                    return <th key={i}>{k}</th>
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {Object.keys(v.key).map((k, i) => {
                                    return <td key={i}>{v.key[k].value}</td>
                                })}
                            </tr>
                            </tbody>
                        </Table>
                    </td>
                    <td>
                        <Table bordered hover size={"sm"} responsive={true}>
                            <thead>
                            <tr>
                                {Object.keys(v.data).map((k, i) => {
                                    return <td key={i}>{k}</td>
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {Object.keys(v.data).map((k, i) => {
                                    return <td key={i}>{v.data[k]}</td>
                                })}
                            </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
            })
            }
            </tbody>
        </Table>
    </>

}

const Tables = () => {
    const [loaded, set_loaded] = useState(false)
    const [table_data, set_table_data] = useState(Object)

    const user = User()

    const loadTables = async () => {
        let tables = await get({route: "/tables", token: user.getToken()})

        if (tables.status === 200) {
            set_table_data(sort_json(tables.data))
        }
    }

    const refresh = async () => {
        set_loaded(false)
        await loadTables()
        set_loaded(true)
    }

    useEffect(() => {
        refresh()

    }, [])

    return <Loader loaded={loaded}>
        <Tabs
            defaultActiveKey={Object.keys(table_data)[0]}
            id="uncontrolled-tab-example"
            className="mb-3 mt-3"
        >
            {Object.keys(table_data).map((v, i) => {
                return <Tab key={i} eventKey={v} title={v}>
                    {table_data[v].map((e: any, i: number) => {
                        return <TableView key={i} name={e.table} data={e.data}/>
                    })
                    }

                </Tab>
            })
            }
        </Tabs>
        <Button onClick={refresh} className={"mt-3"}><i className="bi bi-arrow-clockwise"/> Refresh</Button>
    </Loader>
}

export default Tables;