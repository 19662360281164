import {Form, Table} from "react-bootstrap";
import {PortStatus} from "../sites/Ports";
import ConfirmBox from "./ConfirmBox";
import React, {useState} from "react";

import {patch} from "../common/API"
import User from "../state/user";

const MirrorhaulEntry = ({
                             port_status,
                             port_mapper,
                             rate,
                             data,
                             remove
                         }: {
    port_status: (sw: string, dev: number) => boolean,
    port_mapper: (sw: string, dev: number) => string,
    rate: (sw: string, type: number, pid: number) => number,
    data: {
        id: number
        switch1: string,
        switch2: string,
        duplication_ingress: number,
        duplication_egress_1: number,
        duplication_egress_2: number,
        deduplication_ingress_1: number,
        deduplication_ingress_1_rtt: number | string,
        deduplication_ingress_2: number,
        deduplication_ingress_2_rtt: number | string,
        deduplication_egress: number,
        active: boolean,
        stats_duplicate: {
            "ingress_port": number,
            "pkt_count": number,
            "byte_count": number
        },
        stats_deduplicate: {
            "pkt_count": number,
            "byte_count": number
        }
    },
    remove: (id: number) => void
}) => {

    const [active, set_active] = useState(data.active)
    const [enabled, set_enabled] = useState(true)

    const user = User()

    const onChange = async (event: any) => {
        set_active(!active)
        set_enabled(false)

        let r = await patch({route: "/mirrorhaul/" + data.id, body: {active: !active}, token: user.getToken()})

        if (r != undefined && r.status !== 200) {
            set_active(!active)
        }

        set_enabled(true)
    }

    const formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const formatNanoSeconds = (ns: number | string, decimals: number = 2) => {
        if(typeof ns == "string") {
            return ns
        }

        if (ns === 0) return '0 ns';

        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['ns', 'us', 'ms', 's', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(ns) / Math.log(k));

        return parseFloat((ns / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let duplication_ingress_status = port_status(data.switch1, data.duplication_ingress)
    let duplication_egress_1_status = port_status(data.switch1, data.duplication_egress_1)
    let duplication_egress_2_status = port_status(data.switch1, data.duplication_egress_2)

    let deduplication_ingress_1_status = port_status(data.switch2, data.deduplication_ingress_1)
    let deduplication_ingress_2_status = port_status(data.switch2, data.deduplication_ingress_2)
    let deduplication_egress_status = port_status(data.switch2, data.deduplication_egress)

    return <tr>
        <td>{data.switch1}</td>
        <td>{data.duplication_ingress} ({port_mapper(data.switch1, data.duplication_ingress)} <PortStatus
            active={duplication_ingress_status}/>)
            <br/>
            {/*<i className="bi bi-box-fill"/> {data.stats_duplicate.pkt_count ? formatPackets(data.stats_duplicate.pkt_count) : 0} <br/>*/}
            <i className="bi bi-server"/> {data.stats_duplicate.byte_count ? formatBytes(data.stats_duplicate.byte_count) : 0}
            <br />
            (TX: {(rate(data.switch1, 0, data.duplication_ingress) * 8 / 10**9).toFixed(2)} Gbps)
        </td>
        <td>{data.duplication_egress_1} ({port_mapper(data.switch1, data.duplication_egress_1)} <PortStatus
            active={duplication_egress_1_status}/>
            ) (TX: {(rate(data.switch1, 0, data.duplication_egress_1) * 8 / 10**9).toFixed(2)} Gbps)
            <br/>
            {data.duplication_egress_2} ({port_mapper(data.switch1, data.duplication_egress_2)} <PortStatus
                active={duplication_egress_2_status}/>
            ) (TX: {(rate(data.switch1, 0, data.duplication_egress_2) * 8 / 10**9).toFixed(2)} Gbps)
        </td>
        <td>{data.switch2}</td>
        <td>{data.deduplication_ingress_1} ({port_mapper(data.switch2, data.deduplication_ingress_1)} <PortStatus
            active={deduplication_ingress_1_status}/>
            )   <i className="bi bi-clock-fill" /> {data.deduplication_ingress_1_rtt != "Unknown" ? formatNanoSeconds(data.deduplication_ingress_1_rtt) : "Unknown"}
            {" "} (RX: {(rate(data.switch2, 1, data.deduplication_ingress_1) * 8 / 10**9).toFixed(2)} Gbps)
            <br/>
            {data.deduplication_ingress_2} ({port_mapper(data.switch2, data.deduplication_ingress_2)} <PortStatus
                active={deduplication_ingress_2_status}/>
            )  <i className="bi bi-clock-fill" /> {data.deduplication_ingress_2_rtt != "Unknown" ? formatNanoSeconds(data.deduplication_ingress_2_rtt) : "Unknown"}
            {" "} (RX: {(rate(data.switch2, 1, data.deduplication_ingress_2) * 8 / 10**9).toFixed(2)} Gbps)

        </td>
        <td>{data.deduplication_egress} ({port_mapper(data.switch2, data.deduplication_egress)} <PortStatus
            active={deduplication_egress_status}/>)
            <br/>
            {/*<i className="bi bi-box-fill"/> {data.stats_deduplicate.pkt_count ? formatPackets(data.stats_deduplicate.pkt_count) : 0} <br/>*/}
            <i className="bi bi-server"/> {data.stats_deduplicate.byte_count ? formatBytes(data.stats_deduplicate.byte_count) : 0}
            <br />
            (TX: {(rate(data.switch2, 0, data.deduplication_egress) * 8 / 10**9).toFixed(2)} Gbps)

        </td>
        <td>
            <Form.Check
                inline
                checked={active}
                disabled={!enabled}
                onChange={onChange}
                type="switch"
                role="button"
            />
            <ConfirmBox onConfirm={() => {
                remove(data.id)
            }}
                        text={"Remove Mirrorhaul process?"}>
                <i className={"bi bi-trash-fill"} />
            </ConfirmBox>
        </td>
    </tr>


}
const MirrorhaulView = ({
                            port_status,
                            port_mapper,
                            rate,
                            data,
                            remove
                        }: {
    port_status: (sw: string, dev: number) => boolean,
    port_mapper: (sw: string, dev: number) => string,
    rate: (sw: string, type: number, pid: number) => number,
    data: {
        id: number
        switch1: string,
        switch2: string,
        duplication_ingress: number,
        duplication_egress_1: number,
        duplication_egress_2: number,
        deduplication_ingress_1: number,
        deduplication_ingress_1_rtt: number | string,
        deduplication_ingress_2: number,
        deduplication_ingress_2_rtt: number | string,
        deduplication_egress: number,
        active: boolean,
        stats_duplicate: {
            "ingress_port": number,
            "pkt_count": number,
            "byte_count": number
        },
        stats_deduplicate: {
            "pkt_count": number,
            "byte_count": number
        }
    }[],
    remove: (id: number) => void
}) => {
    return <Table bordered hover size={"sm"} responsive={true}>
        <thead className={"table-dark"}>
        <tr>
            <th>Duplication</th>
            <th>Ingress</th>
            <th>Egress</th>
            <th>Deduplication</th>
            <th>Ingress</th>
            <th>Egress</th>
            <th>Options</th>
        </tr>
        </thead>
        <tbody>

        {data.map((v, i) => {
            return <MirrorhaulEntry key={i} rate={rate} port_status={port_status} port_mapper={port_mapper} data={v} remove={remove}/>
        })
        }

        </tbody>
    </Table>
}

export default MirrorhaulView